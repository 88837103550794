<template>
  <div v-if="asset.type === 'Image' && !asset.signedUrl && !src" class="image default"></div>
  <div
    v-else-if="asset.type === 'Image' && $route.path === '/asset-library'"
    class="image"
    :style="`backgroundImage: url(${asset.signedUrl || src || asset.assetURL})`"
  ></div>

  <img v-else-if="asset.type === 'Image'" :src="asset.signedUrl || src || asset.assetURL" alt="" />

  <div
    v-else-if="asset.type === 'Video' && !poster && !src && !asset.thumbnailUrl && !asset.thumbnail && !asset.assetURL"
    class="video default"
  ></div>

  <div
    v-else-if="asset.type === 'Video' && $route.path !== '/asset-library' && !$route.path.includes('edit-course')"
    class="video plyr__video-embed"
  >
    <VuePlyr ref="plyr" :options="options">
      <iframe
        v-if="type === 'vimeo'"
        :src="`https://player.vimeo.com/video/${embedId}?loop=false&byline=false&portrait=false&title=false&speed=true&transparent=0&gesture=media`"
        allowfullscreen
        allowtransparency
        allow="autoplay"
      >
      </iframe>
      <video
        v-else-if="type === 'video'"
        :poster="poster || src || asset.thumbnailUrl || thumbnail"
        :src="src || asset.assetURL"
        crossorigin
        playsinline
      >
        <!-- <track kind="captions" label="English" srclang="en" :src="captions" default /> -->
      </video>
      <div v-else :data-plyr-provider="type" :data-plyr-embed-id="embedId"></div>
    </VuePlyr>
  </div>

  <div
    v-else-if="(asset.type === 'Video' && $route.path === '/asset-library') || $route.path.includes('edit-course')"
    class="image"
    :style="`backgroundImage: url(${asset.thumbnailUrl || poster || asset.assetURL})`"
  >
    <span class="play-icon"><PlaySVG /></span>
  </div>
  <!-- <img
    v-else-if="(asset.type === 'Video' && $route.path === '/asset-library') || $route.path.includes('edit-course')"
    class="image"
    :src="asset.thumbnail || poster || asset.assetURL"
  /> -->
</template>

<script>
import axios from 'axios';
import VuePlyr from 'vue-plyr';
import PlaySVG from '@/assets/icons/plyr-play.svg';

export default {
  name: 'presigned-image',
  props: ['asset', 'courseID', 'moduleBeingEdited', 'sectionBeingEdited', 'assetIndex'],
  components: {
    VuePlyr,
    PlaySVG,
  },
  created() {
    this.getThumbnail();
  },
  updated() {
    this.getThumbnail();
  },
  computed: {
    type() {
      if (this.asset.assetURL.indexOf('youtube') > -1) return 'youtube';
      if (this.asset.assetURL.indexOf('vimeo') > -1) return 'vimeo';
      return 'video';
    },
    embedId() {
      const regex = /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/g;
      return regex.exec(this.asset.assetURL)[6] || '';
    },
  },
  methods: {
    getThumbnail() {
      if (this.courseID) {
        axios
          .get(`${process.env.VUE_APP_API_URL}/courses/${this.courseID}`, {
            headers: { authorization: sessionStorage.getItem('vwcup-token') },
          })
          .then((response) => {
            // handle success
            axios
              .get(
                `${process.env.VUE_APP_API_URL}/assets/${
                  response.data.data.course.modules[this.moduleBeingEdited].sections[this.sectionBeingEdited].assets[
                    this.assetIndex
                  ].id
                }`,
                {
                  headers: { authorization: sessionStorage.getItem('vwcup-token') },
                },
              )
              .then((response2) => {
                // handle success
                if (response2.data.data.thumbnailUrl) {
                  this.poster = response2.data.data.thumbnailUrl;
                }
                this.src = response2.data.data.signedUrl;
              })
              .catch((error) => {
                // handle error
                console.error(error);
                axios
                  .get(
                    `${process.env.VUE_APP_API_URL}/assets/${
                      response.data.data.course.modules[this.moduleBeingEdited].sections[this.sectionBeingEdited]
                        .assets[this.assetIndex].id + 1
                    }`,
                    {
                      headers: { authorization: sessionStorage.getItem('vwcup-token') },
                    },
                  )
                  .then((response3) => {
                    // handle success
                    if (response3.data.data.thumbnailUrl) {
                      this.poster = response3.data.data.thumbnailUrl;
                    }
                    this.src = response3.data.data.signedUrl;
                  });
              });
          })
          .catch((error) => {
            // handle error
            console.error(error);
          });
      } else if (this.asset && this.asset.filePath && !this.asset.filePath.startsWith('media/')) {
        this.src = this.asset.filePath;
      } else {
        axios
          .get(`${process.env.VUE_APP_API_URL}/assets/${this.asset.id}`, {
            headers: { authorization: sessionStorage.getItem('vwcup-token') },
          })
          .then((response) => {
            // handle success
            if (response.data.data.thumbnailUrl) {
              this.poster = response.data.data.thumbnailUrl;
            }
            this.src = response.data.data.signedUrl;
          })
          .catch((error) => {
            // handle error
            console.error(error);
          });
      }
    },
  },
  data() {
    return {
      src: '',
      poster: null,
      options: {
        controls: [
          'controls',
          'play-large', // The large play button in the center
          'rewind', // Rewind by the seek time (default 10 seconds)
          'play', // Play/pause playback
          'progress', // The progress bar and scrubber for playback and buffering
          'current-time', // The current time of playback
          'mute', // Toggle mute
          'volume', // Volume control
          'captions', // Toggle captions
          'settings', // Settings menu
          'fullscreen', // Toggle fullscreen
        ],
        settings: ['captions'],
        // autoplay: false,
        // clickToPlay: true,
        displayDuration: true,
        toggleInvert: false,
        volume: 1,
        keyboard: {
          focused: false,
          global: false,
        },
        fullscreen: {
          enabled: true,
          fallback: false,
          iosNative: false,
        },
        // listeners: {
        //   seek: this.preventSeek,
        // },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
img,
video,
.image {
  width: 100%;
  // background-size: contain;
  background-size: cover !important;
  background-position: center center;
}
.image {
  // height: 170px;
  padding-bottom: 56%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &.default {
    background: $grey;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 56%;
    background: url('../assets/icons/noImage.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  > .play-icon {
    background: $primary-light;
    border: 0;
    border-radius: 100%;
    color: #fff;
    left: 50%;
    opacity: 0.9;
    padding: 15px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    > svg {
      left: 2px;
      position: relative;
      display: block;
      width: 18px;
      height: 18px;
    }
  }
}
.video {
  // height: 100px;
  ::v-deep {
    video {
      width: 100%;
    }
    .plyr {
      .plyr--full-ui input[type='range'] {
        color: $primary-light;
      }
      .plyr--video .plyr__control:hover,
      .plyr__control--overlaid,
      .plyr--video .plyr__control .plyr__tab-focus,
      .plyr--video .plyr__control:hover,
      .plyr--video .plyr__control[aria-expanded='true'] {
        background: $primary-light;
      }
    }
  }
  &.default {
    background: $grey;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 56%;
    background: url('../assets/icons/noVideo.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

::v-deep {
  .plyr__control--overlaid {
    background: var(--plyr-color-main, var(--plyr-color-main, #00b3ff));
    background: var(--plyr-video-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
    border: 0;
    border-radius: 100%;
    color: #fff;
    color: var(--plyr-video-control-color, #fff);
    display: none;
    left: 50%;
    opacity: 0.9;
    padding: calc(10px * 1.5);
    padding: calc(var(--plyr-control-spacing, 10px) * 1.5);
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s;
    z-index: 2;
  }
}
.plyr__video-embed {
  padding: 0;
  height: auto;
}
</style>
