<template>
  <div class="checkbox">
    <input :type="type" :name="name" :id="buttonID" :disabled="disabled" :checked="value" @change="toggle" />
    <label :for="buttonID">{{ getLabel }}</label>
  </div>
</template>

<script>
export default {
  name: 'checkbox',
  props: {
    name: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    label: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'checkbox',
    },
  },
  computed: {
    buttonID() {
      // eslint-disable-next-line no-underscore-dangle
      return this.id || `checkbox-${this._uid}`;
    },
    getLabel() {
      if (this.label !== 'Web-Based Training') {
        return this.label;
      }
      return 'WBT';
    },
  },
  methods: {
    toggle() {
      this.$emit('input', !this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  label {
    font-size: 1.4rem;
    line-height: 25px;
    display: block;
    padding-left: 40px;
    position: relative;
    @include lg {
      color: $primary-black;
    }

    &::before {
      content: '';
      background-color: $primary-white;
      border: 1px solid $light-grey;
      position: absolute;
      top: 50%;
      left: 0;
      width: 23px;
      height: 23px;
      transform: translateY(-50%);
    }
  }
  input[type='checkbox'] {
    display: none;

    &:checked + label::before {
      border-color: #00b1eb;
      background-color: #00b1eb;
      background-image: url('../assets/icons/checkbox.svg?external');
      background-position: -1px -1px;
    }
  }
  input[type='radio'] {
    display: none;

    &:checked + label {
      &::before {
        background-color: $primary-light;
        border-color: $primary-light;
      }
      &::after {
        content: '';
        background-color: $primary-white;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 7px;
        width: 11px;
        height: 11px;
        transform: translateY(-50%);
      }
    }
    + label::before {
      border-radius: 50%;
    }
  }
}
</style>
