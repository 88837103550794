<template>
  <div class="courseLibrary">
    <Loading :active.sync="visible"></Loading>
    <div class="tags">
      <div class="tag filter">
        <div class="filter" @click.stop="toggleFilterModal">
          <span>Filter</span>
          <div class="carat" :class="{ open: filterModalOpen }"></div>
        </div>
      </div>
      <div class="tag show">
        <Multiselect v-model="showTag" :options="showOptions" :show-labels="false" :allow-empty="false"></Multiselect>
      </div>
      <div class="search">
        <SearchSVG class="search-icon" />
        <input type="text" v-model="search" v-debounce:500ms="myFunc" placeholder="Search" />
      </div>
      <transition :name="transitionName">
        <div v-if="filterModalOpen" class="filter-modal" v-click-outside="{ handler: toggleFilterModal }">
          <div class="multiselects">
            <div class="jobs">
              <span>Job Role(s)</span>
              <br />
              <br />
              <Multiselect
                v-model="jobTag"
                :options="jobRolesWithAny"
                :show-labels="false"
                :allow-empty="false"
              ></Multiselect>
            </div>
            <div class="part">
              <span>Part Of Course</span>
              <br />
              <br />
              <Multiselect
                v-model="partTag"
                :options="courseNamesWithAny || []"
                :show-labels="false"
                :allow-empty="false"
              ></Multiselect>
            </div>
          </div>
          <div class="checklist">
            <div class="assetType">
              <h1>Asset Type:</h1>
              <ul>
                <li v-for="(assetType, index) in assetTypes" :key="index">
                  <Checkbox :label="assetType.name" v-model="assetType.checked" />
                </li>
              </ul>
            </div>
            <div class="button">
              <button class="primary-btn" @click="toggleFilterModal">Apply</button>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <!-- Grid -->
    <div class="courseGrid">
      <div v-for="(course, index) in assets.assets" :key="index" class="course" @click="goTo(course.courseID)">
        <div
          v-if="
            course.type.toUpperCase() === 'WEB-BASED TRAINING' ||
            course.type.toUpperCase() === 'PDF' ||
            course.type.toUpperCase() === 'WEBSITE' ||
            course.type.toUpperCase() === 'SLIDESHOW'
          "
          class="thumbnail"
          :class="[
            course.type.toUpperCase() === 'PDF' ? 'pdf' : '',
            course.type.toUpperCase() === 'WEB-BASED TRAINING' ? 'wbt' : '',
            course.type.toUpperCase() === 'WEBSITE' ? 'website' : '',
            course.type.toUpperCase() === 'SLIDESHOW' ? 'slideshow' : '',
          ]"
        ></div>
        <!-- <img class="thumbnail" v-else :src="course.signedUrl" alt="" /> -->
        <SignedImage v-else :asset="course" />
        <h2 :class="{ italic: course.title === '' }">{{ course.title || 'No Title' }}</h2>
        <span class="meta"
          ><span class="type">{{ course.type }}</span
          >{{ course.name }}</span
        >
      </div>
      <div v-if="numAssetsShown % 3 !== 0" class="course after"></div>
    </div>

    <div v-if="!assets || !assets.assets || !assets.assets.length" class="no-content">
      <div
        v-if="
          partOfCourse ||
          selectedAssets !== 'Web-Based%20Training,PDF,Website,Video,Image,Slideshow' ||
          jobCodes ||
          search
        "
      >
        No assets match your search criteria.
        <br />
        Try broadening your search by removing some filters.
      </div>
      <div v-else>
        You don’t have anything in the Asset Library.
        <br />
        When you see courses in your Digital Passport, the assets from those courses will appear here.
      </div>
    </div>

    <div
      v-if="assets.assets && assets.assets.length && assets.assets.length < assets.metaData.totalResults"
      class="paginator-container"
    >
      <div class="leftArrow" @click="decrementPage">
        <Arrow />
      </div>
      <Paginate v-model="page" :page-count="numPages" :prev-text="''" :next-text="''" :container-class="'paginator'">
      </Paginate>
      <div class="rightArrow" @click="incrementPage">
        <Arrow />
      </div>
    </div>
    <!-- <footer class="footer">© 2020 Volkswagen Academy</footer> -->
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import SearchSVG from '@/assets/icons/search_icon.svg';
import Checkbox from '@/components/Checkbox.vue';
import Paginate from 'vuejs-paginate';
import Arrow from '@/assets/icons/arrow.svg';
import Loading from 'vue-loading-overlay';
import axios from 'axios';
import Vue from 'vue';
import vueDebounce from 'vue-debounce';
import SignedImage from '@/components/Thumbnail.vue';
import { mapGetters } from 'vuex';

Vue.use(vueDebounce);

export default {
  components: {
    Multiselect,
    SearchSVG,
    Checkbox,
    Paginate,
    Arrow,
    Loading,
    SignedImage,
  },
  created() {
    this.getAssets();
    this.$store.dispatch('getCourses').catch((e) => {
      console.error(e);
    });
    this.$store.dispatch('getJobRoles').catch((e) => {
      console.error(e);
    });
  },
  computed: {
    ...mapGetters(['jobRoles', 'jobRoleNames', 'courses', 'courseNames', 'userInfo']),
    transitionName() {
      return this.$mq === 'lg' ? 'scale' : 'slide';
    },
    numCourses() {
      if (this.assets && this.assets.metaData && this.assets.metaData.totalResults) {
        return this.assets.metaData.totalResults;
      }
      return 0;
    },
    perPage() {
      if (this.showTag === 'Show 5') {
        return 5;
      }
      if (this.showTag === 'Show 10') {
        return 10;
      }
      if (this.showTag === 'Show 20') {
        return 20;
      }
      return -1;
    },
    numPages() {
      if (this.perPage > this.numCourses) {
        return 1;
      }
      return Math.ceil(this.numCourses / this.perPage);
    },
    partOfCourse() {
      if (this.partTag === 'All Courses') {
        return '';
      }
      let x = '';
      if (this.courses && this.courses.length) {
        this.courses.forEach((course) => {
          if (course.name === this.partTag) {
            x = course.courseNumber;
          }
        });
      }
      return x;
    },
    jobCodes() {
      if (this.jobTag === 'All Job Roles') {
        return '';
      }
      let x = '';
      if (this.jobRoles && this.jobRoles.length) {
        this.jobRoles.forEach((job) => {
          if (job.name === this.jobTag) {
            x = job.code;
          }
        });
      }
      return x;
    },
    selectedAssets() {
      const x = [];
      if (this.assetTypes && this.assetTypes.length) {
        this.assetTypes.forEach((asset) => {
          if (asset.checked) {
            x.push(asset.name);
          }
        });
      }
      if (!x.length) {
        return 'Web-Based%20Training,PDF,Website,Video,Image,Slideshow';
      }
      return x.join();
    },
    jobRolesWithAny() {
      const x = ['All Job Roles'];
      if (this.jobRoleNames && this.jobRoleNames.length) {
        this.jobRoleNames.forEach((job) => {
          x.push(job);
        });
      }
      return x;
    },
    courseNamesWithAny() {
      const x = ['All Courses'];
      if (this.courseNames && this.courseNames.length) {
        this.courseNames.forEach((course) => {
          x.push(course);
        });
      }
      return x;
    },
    numAssetsShown() {
      if (this.assets.assets && this.assets.assets.length < this.perPage) {
        return this.assets.assets.length;
      }
      return this.perPage;
    },
  },
  watch: {
    jobTag() {
      if (this.jobTag === null) {
        this.jobTag = 'All Job Roles';
      }
    },
    partTag() {
      if (this.partTag === null) {
        this.partTag = 'All Courses';
      }
      this.getAssets();
    },
    showTag() {
      if (this.showTag === null) {
        this.showTag = 'Show 5';
      }
      this.page = 1;
    },
    limit() {
      this.getAssets();
      this.page = 1;
    },
    perPage() {
      this.getAssets();
    },
    page() {
      this.getAssets();
    },
    // numPages() {
    //   this.page = 1;
    // },
    courses() {
      this.getAssets();
    },
    jobCodes() {
      this.getAssets();
    },
    selectedAssets() {
      this.page = 1;
      this.getAssets();
    },
  },
  methods: {
    toggleFilterModal() {
      this.filterModalOpen = !this.filterModalOpen;
    },
    decrementPage() {
      if (this.page > 1) {
        this.page -= 1;
      }
    },
    incrementPage() {
      if (this.page < this.numPages) {
        this.page += 1;
      }
    },
    myFunc() {
      this.getAssets();
      this.page = 1;
    },
    getAssets() {
      this.visible = true;
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/assets/?page=${this.page - 1}&limit=${this.perPage}&courses=${
            this.partOfCourse
          }&types=${this.selectedAssets}&jobCodes=${this.jobCodes}&like=${this.search}`,
          {
            headers: { authorization: sessionStorage.getItem('vwcup-token') },
          },
        )
        .then((response) => {
          // handle success
          this.assets = response.data.data;
          this.visible = false;
        })
        .catch((error) => {
          // handle error
          console.error(error);
          this.visible = false;
        })
        .finally(() => {
          // always executed
        });
    },
    goTo(courseID) {
      if (this.userInfo.ILTRoleID === 3 || this.userInfo.ILTRoleID === 4) {
        window.open(`/edit-course/${courseID.toString()}`, '_blank');
      } else {
        window.open(`/course/${courseID.toString()}`, '_blank');
      }
    },
  },
  data() {
    return {
      // totalAssets: null,
      visible: false,
      assets: [],
      page: 1,
      jobTag: 'All  Job Roles',
      jobOptions: ['All Job Roles', 'some job', 'some other job'],
      partTag: 'All Courses',
      partOptions: ['All Courses', 'some part', 'some other part'],
      showTag: 'Show 5',
      showOptions: ['Show 5', 'Show 10', 'Show 20'],
      search: '',
      filterModalOpen: false,
      assetTypes: [
        // {
        //   name: 'All',
        //   checked: false,
        // },
        {
          name: 'Web-Based Training',
          checked: false,
        },
        {
          name: 'PDF',
          checked: false,
        },
        {
          name: 'Website',
          checked: false,
        },
        {
          name: 'Video',
          checked: false,
        },
        {
          name: 'Image',
          checked: false,
        },
        {
          name: 'Slideshow',
          checked: false,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.courseLibrary {
  .tags {
    height: 100%;
    /* IE 10+ Styles */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      height: 54px;
    }
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 14px;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;
    @include md {
      width: 100%;
    }
    > .tag {
      width: 210px;
      // flex: 1;
      // border: 1px solid #fff;
      height: 40px;
      border-radius: 3px;
      line-height: 40px;
      margin-right: 15px !important;
      margin-bottom: 14px;
      @include md {
        margin: 0 auto 14px 0;
      }
      &.filter {
        width: 100%;
        @include md {
          width: 210px;
        }
      }
      .arrow {
        width: 15px;
        position: absolute;
        margin-top: 15px;
        // margin-left: 75px;
        &.flip {
          transform: scaleY(-1);
        }
      }
      .filter {
        text-align: left;
        height: 40px !important;
        padding: 0 0 0 15px !important;
        border: 1px solid $light-grey;
        cursor: pointer;
        color: #000;
        background: #fff;
        border-radius: 3px;
        display: flex;
        justify-content: space-between;

        @include lg {
          // line-height: 1.125;
          border: 1px solid #dfe4e8;
          // border-radius: 3px;
          padding: 7px 55px 9px 15px;
          height: auto;
        }
        span {
          vertical-align: middle;
          font-size: 1.6rem;
          font-family: $head-font-stack;
          font-weight: 300;
        }
        .carat {
          width: 16px;
          background: url('~@/assets/icons/carat.svg?external') center center no-repeat;
          transition: transform 300ms;
          margin-right: 15px;
          &.open {
            transform: rotate(180deg);
            transition: transform 300ms;
          }
        }
        .icon-badge {
          margin-left: 5px;
          vertical-align: middle;
          font-size: 14px;
          background: #00b1eb !important;
          color: white !important;

          @include lg {
            margin-left: 10px;
          }
        }
        &.moveLeft {
          &::after {
            margin-left: 102px;
          }
        }
      }
      &.show {
        width: 50% !important;
        @include md {
          width: 124px !important;
        }
      }
    }
    > .filterType {
      display: flex;
      align-items: center;
      width: 60px;
      margin: 0 17px 14px auto;
      @include md {
        margin-right: 26px;
      }
      > .list {
        margin: 0 0 0 auto;
        width: 24px;
        cursor: pointer;
        &.activeFilterType {
          color: $primary-light;
        }
      }
      > .grid {
        margin: 0 0 0 auto;
        width: 20px;
        cursor: pointer;
        &.activeFilterType {
          color: $primary-light;
        }
      }
    }
    > .search {
      width: calc(100% - 17px);
      border: 1px solid #fff;
      height: 40px;
      border-radius: 3px;
      line-height: 40px;
      display: flex;
      background: #fff;
      border: 1px solid $light-grey;
      margin-bottom: 14px;
      @include md {
        width: 240px;
      }
      > input {
        height: 100%;
        width: calc(100% - 35px);
        padding: 0;
        border: none;
        padding: 0 15px;
        color: #000 !important;
        outline: none;
        height: calc(100% - 2px);
        margin-top: 1px;
        font-weight: 300;
        &:focus {
          outline: none;
        }
        &::placeholder {
          color: #000;
          opacity: 0.5;
          font-weight: 300;
        }
      }
      .search-icon {
        width: 15px;
        margin-left: 10px;
      }
    }
  }
  // filter modal
  .filter-modal {
    color: #000;
    display: flex;
    z-index: 100;

    width: calc(100% - 17px);

    @include md {
      margin-top: 70px !important;
      // border: 1px solid white;
      background: #fff;
      box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.29);
      border: 1px solid $light-grey;
      border-radius: 0px 3px 3px 3px;
      margin-top: -1px;
      padding: 32px 35px 45px 35px;
      position: absolute;
      // top: 179px;
      top: 112px;
      // left: 75px;
      left: calc(20vw + 67px);
      width: auto;
      min-width: 492px;
      height: auto;
    }
    > .multiselects {
      flex: 1;
      margin-right: 10px;
      @include md {
        margin-right: 50px;
      }
      > .jobs {
        margin-bottom: 25px;
        > span {
          font-family: $head-font-stack;
          font-weight: 700;
        }
        .filter {
          font-family: $head-font-stack;
          font-weight: 300;
          text-align: left;
          height: 40px !important;
          padding: 0 0 0 15px !important;
          border: 1px solid $light-grey;
          cursor: pointer;
          color: #000;
          background: #fff;
          border-radius: 3px;
          width: 195px;

          @include lg {
            // line-height: 1.125;
            border: 1px solid #dfe4e8;
            // border-radius: 3px;
            padding: 7px 55px 9px 15px;
            height: auto;

            &::after {
              content: '';
              background-image: url('../../assets/icons/carat.svg?external');
              background-repeat: no-repeat;
              background-size: 16px 8px;
              position: absolute;
              margin-top: 20px;
              margin-left: 130px;
              // margin-top: 20px;
              // margin-left: 130px;
              width: 16px;
              height: 8px;
              transform: translateY(-50%);
              transition: transform 300ms;
            }
            &.open::after {
              transform: rotate(180deg) translateY(50%);
            }
          }
          span {
            vertical-align: middle;
            line-height: 40px;
            font-size: 1.6rem;
          }
          .icon-badge {
            margin-left: 5px;
            vertical-align: middle;
            font-size: 14px;
            background: #00b1eb !important;
            color: white !important;

            @include lg {
              margin-left: 10px;
            }
          }
          &.moveLeft {
            &::after {
              margin-left: 102px;
            }
          }
        }
      }
      > .part {
        > span {
          font-family: $head-font-stack;
          font-weight: 700;
        }
        .filter {
          font-family: $head-font-stack;
          font-weight: 300;
          text-align: left;
          height: 40px !important;
          padding: 0 0 0 15px !important;
          border: 1px solid $light-grey;
          cursor: pointer;
          color: #000;
          background: #fff;
          border-radius: 3px;
          width: 195px;

          @include lg {
            // line-height: 1.125;
            border: 1px solid #dfe4e8;
            // border-radius: 3px;
            padding: 7px 55px 9px 15px;
            height: auto;

            &::after {
              content: '';
              background-image: url('../../assets/icons/carat.svg?external');
              background-repeat: no-repeat;
              background-size: 16px 8px;
              position: absolute;
              margin-top: 20px;
              margin-left: 130px;
              // margin-top: 20px;
              // margin-left: 130px;
              width: 16px;
              height: 8px;
              transform: translateY(-50%);
              transition: transform 300ms;
            }
            &.open::after {
              transform: rotate(180deg) translateY(50%);
            }
          }
          span {
            vertical-align: middle;
            line-height: 40px;
            font-size: 1.6rem;
          }
          .icon-badge {
            margin-left: 5px;
            vertical-align: middle;
            font-size: 14px;
            background: #00b1eb !important;
            color: white !important;

            @include lg {
              margin-left: 10px;
            }
          }
          &.moveLeft {
            &::after {
              margin-left: 102px;
            }
          }
        }
      }
    }
    .checklist {
      flex: 1;
      > .assetType {
        @include md {
          margin-bottom: 30px;
        }
        > h1 {
          font-family: $head-font-stack;
          font-weight: 300;
          padding: 0;
          margin-top: 0;
          margin-bottom: 10px;
          font-size: 1.8rem;
        }
      }
      > .button {
        > .primary-btn {
          margin: 0 0 20px auto;
          @include md {
            margin-bottom: 0;
          }
        }
      }
    }
    ul {
      margin: 0;
      padding: 20px 0;
      position: relative;
      z-index: 1;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      list-style: none;

      @include lg {
        background: none;
        padding: 0;
      }
      li {
        padding: 0 20px 0 28px;
        color: $primary-black;
        font-family: $head-font-stack;
        font-weight: 300;
        width: 50%;

        @include lg {
          padding: 0;
        }
      }
      .checkbox {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    .filter-list {
      border-top: 1px solid $light-grey;
      @media only screen and (max-width: 1000px) {
        margin-top: 75px;
      }
      ::v-deep {
        h3 {
          padding-left: 20px;
          color: black !important;
        }
        label {
          color: black;
        }
        label::before {
          border: 1px solid black;
        }
        .icon-badge {
          font-size: 14px;
          background: #00b1eb !important;
          color: white !important;
        }
        @include lg {
          border-top: 0;

          h3 {
            font-size: 22px;
            border: 0;
            box-shadow: none;
            margin-bottom: 15px;
            padding: 0;
            background: transparent;
            font-weight: normal;
            color: white;

            span {
              vertical-align: baseline;
            }
            &::after {
              content: ':';
              background: none;
              position: static;
              vertical-align: baseline;
            }
          }
          .icon-badge {
            display: none;
          }
          .collapsible-content {
            background: none;
            box-shadow: none;
            max-height: none;
            padding: 0;
            // text-transform: uppercase;
          }
        }
      }
    }
    // .filter-list h3,
    .filter-toggles h3,
    .filter-toggles ::v-deep label {
      font-size: 1.6rem;
      font-weight: 300;
      display: block;
      margin: 0;
      padding: 22px 20px 26px 28px;
      position: relative;
      z-index: 2;

      @include lg {
        font-size: 2rem;
        border: 0;
        box-shadow: none;
        padding: 0;
      }
      .icon-badge {
        margin-top: 0.125em;
        margin-left: 10px;
        position: absolute;

        @include lg {
          display: none;
        }
      }
    }
    .filter-toggles {
      @include lg {
        margin-left: 50px;
      }
      h3 {
        display: none;

        @include lg {
          display: block;
          margin-bottom: 15px;

          &::after {
            content: ':';
          }
        }
      }
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
          @include lg {
            padding: 10px 0;
          }
        }
      }
      ::v-deep {
        label {
          @include lg {
            font-size: inherit;
            padding-left: 70px;
          }
          span {
            position: relative;

            @include lg {
              position: static;
            }
          }
        }
      }
    }
  }
  > .courseGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    > .course {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      width: 80%;
      margin: 20px auto 0 auto;
      @include mdp {
        width: calc(33% - 12px);
        margin: 0 0 24px 0;
      }
      > .thumbnail {
        // height: 170px;
        padding-bottom: 56%;
        background: $primary-lighter;
        background-size: cover;
        background-position: center;
        cursor: pointer;
        &.pdf {
          background-image: url('../../assets/icons/pdf_thumbnail.svg?external');
        }
        &.wbt {
          background-image: url('../../assets/icons/wbt_thumbnail.svg?external');
        }
        &.website {
          background-image: url('../../assets/icons/link_thumbnail.svg?external');
        }
        &.slideshow {
          background-image: url('../../assets/icons/slideshow_thumbnail.svg?external');
        }
        > .icon {
          max-width: 25.179856%;
          max-height: 50.955414%;
        }
      }
      > h2 {
        font-family: $head-font-stack;
        font-weight: 700;
        font-size: 1.4rem;
      }
      .meta {
        font-family: $head-font-stack;
        font-weight: 300;
        color: $primary-text;
        font-size: 1.2rem;
        > .type {
          margin-right: 10px;
        }
      }
      img {
        height: 250px !important;
        @include mdp {
          cursor: pointer;
          padding-bottom: 56% !important;
        }
      }
      video {
        height: 250px !important;
        cursor: pointer;
        @include mdp {
          padding-bottom: 56% !important;
        }
      }
    }
    .after {
      @include md {
        content: '';
        opacity: 0;
        pointer-events: 0;
        flex: auto;
        max-width: calc(33% - 12px);
      }
    }
    &.full {
      .after {
        display: none;
      }
    }
  }
  > .paginator-container {
    margin-top: 44px;
    color: #00000050;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    svg {
      height: 20px;
    }
    > .leftArrow {
      width: 12px;
      height: 100%;
      line-height: 30px;
      margin-right: 65px;
      opacity: 0.5;
      cursor: pointer;
    }
    > .rightArrow {
      width: 12px;
      height: 100%;
      line-height: 30px;
      margin-left: 65px;
      transform: rotate(180deg);
      opacity: 0.5;
      cursor: pointer;
    }
    > .paginator {
      font-size: 14px;
      list-style: none;
      display: flex;
      padding: 0;
      // ::v-deep {
      // .active {
      //   a {
      //     color: #000 !important;
      //   }
      // }
      // li {
      //   margin: 0 10px !important;
      // }
      // }
    }
  }
}
.italic {
  font-style: italic;
}

.no-content {
  width: 100%;
  height: 450px;
  // background: $primary-white;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  > div {
    max-width: 90%;
    margin: auto;
  }
}
</style>
